import logo from "./logo.png"
const clientData = {
    client_entity: 49,
    attorney_firm: 'Coleman Legal Group',
    attorney_name: 'Lee Coleman',
    attorney_number: '270-392-0567',
    attorney_email: 'lcoleman@hughesandcoleman.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_accounting_and_business_partners+(720p).mp4',
    mainColor: '#2f2f2f',
    secondaryColor: '#175f9c',
    siteLink: 'http://www.hughesandcoleman.com/attorneys/lee-coleman/',
    logo
}

export default clientData